import { lazy } from "react";

export type DomainConfig = {
  homePath: string;
  routes: {
    [path: string]: React.LazyExoticComponent<React.FC>;
  };
};

export type RouteConfig = {
  [domain: string]: DomainConfig;
};

export const routeConfig: RouteConfig = {
  sanjiang: {
    homePath: "/home",
    routes: {
      "/query": lazy(() => import("../pages/themes/sanjiang/Query")),
      "/history": lazy(() => import("../pages/themes/sanjiang/History")),
      "/home": lazy(() => import("../pages/themes/sanjiang/Home")),
      "/plan": lazy(() => import("../pages/themes/sanjiang/Plan")),
      "/renew": lazy(() => import("../pages/themes/sanjiang/Renew")),
      "/renew/renew-records": lazy(
        () => import("../pages/themes/sanjiang/RenewRecord")
      ),
      "/payDetail": lazy(
        () => import("../pages/themes/sanjiang/Renew/payDetail")
      ),
      "/done": lazy(() => import("../pages/themes/sanjiang/Done")),
      "/docs": lazy(() => import("../pages/themes/sanjiang/Docs")),
    },
  },
  default: {
    homePath: "/query",
    routes: {
      "/query": lazy(() => import("../pages/Query")),
      "/history": lazy(() => import("../pages/History")),
      "/home": lazy(() => import("../pages/Home")),
      "/plan": lazy(() => import("../pages/Plan")),
      "/renew": lazy(() => import("../pages/Renew")),
      "/renew/renew-records": lazy(() => import("../pages/RenewRecord")),
      "/payDetail": lazy(() => import("../pages/Renew/payDetail")),
      "/done": lazy(() => import("../pages/Done")),
    },
  },
};
