// TODO Production environment have not set domain yet

export const getDomain = () => {
  const hostname = window.location.hostname;
  if (
    hostname.includes("localhost") ||
    hostname.includes("10.10.20.53") ||
    hostname === "sanjiang-test-domian.test.simboss.com" ||
	hostname === "sim.vantruecam.com" ||
    hostname === "paypal-yuanyuan.simboss.com"
  )
    return "sanjiang";
  return "default";
};
