import { getDomain } from '../config/domains';
import { routeConfig } from '../config/routes';

export const getComponent = (path: string) => {
    const domain = getDomain();
    const domainConfig = routeConfig[domain] || routeConfig.default;
    return domainConfig.routes[path] || routeConfig.default.routes[path];
};

export const getHomePath = () => {
    const domain = getDomain();
    const domainConfig = routeConfig[domain] || routeConfig.default;
    return domainConfig.homePath;
};
